<template>
  <div
    id="contents"
    class="indivi_regist"
    :class="{
      batch_regist: tabIndex == 0,
      management_reporting: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToBillManagementPage'"
          :options="asideLists"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 2"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <BatchRegistrationForm v-if="tabIndex == 0" />
      <IndividualRegistrationForm v-if="tabIndex == 1" />
      <ManagementReportingForm v-if="tabIndex == 2" />
    </div>
  </div>
</template>

<script>
import BatchRegistrationForm from '@/views/forms/Business/BatchRegistrationForm';
import IndividualRegistrationForm from '@/views/forms/Business/IndividualRegistrationForm';
import ManagementReportingForm from '@/views/forms/Business/ManagementReportingForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    BatchRegistrationForm,
    IndividualRegistrationForm,
    ManagementReportingForm,
  },
  data() {
    return {
      asideLists: [
        { title: '계산서 일괄등록', name: 'batch_registration' },
        { title: '계산서 개별등록', name: 'individual_registration' },
        { title: '계산서 관리 및 신고', name: 'management_reporting' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromBillPage',
      tabIndex: 'getOpenTabIndexFromBillManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToBillPage',
      SetOpenTabIndex: 'setOpenTabIndexToBillManagementPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitBillPageStore');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
