<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="input_radio">
          <h6>과세형태 :</h6>
          <div>
            <label
              for="radio1_1"
              class="label"
              :class="{ active: newData.tax_type_id == 1 }"
              @click="selectTaxType(1)"
            ></label>
            <input
              type="radio"
              id="radio1_1"
              name="search_opt"
              :checked="newData.tax_type_id == 1"
            />
            <label for="radio1_1" class="label_txt">과세</label>
          </div>
          <div>
            <label
              for="radio1_2"
              class="label"
              :class="{ active: newData.tax_type_id == 3 }"
              @click="selectTaxType(3)"
            ></label>
            <input
              type="radio"
              id="radio1_2"
              name="search_opt"
              :checked="newData.tax_type_id == 3"
            />
            <label for="radio1_2" class="label_txt">면세</label>
          </div>
          <div>
            <label
              for="radio1_3"
              class="label"
              :class="{ active: newData.tax_type_id == 2 }"
              @click="selectTaxType(2)"
            ></label>
            <input
              type="radio"
              id="radio1_3"
              name="search_opt"
              :checked="newData.tax_type_id == 2"
            />
            <label for="radio1_3" class="label_txt">영세율</label>
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap bill_tbl">
        <table class="mes_tbl">
          <tbody>
            <tr>
              <td rowspan="6">공급자</td>
              <td>등록번호</td>
              <td colspan="4">
                <input type="text" :value="systemCompany.saup_no" readonly />
              </td>
            </tr>
            <tr>
              <td>상호</td>
              <td>
                <input type="text" :value="systemCompany.name" readonly />
              </td>
              <td>성명</td>
              <td>
                <input
                  type="text"
                  :value="systemCompany.representative"
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>사업장주소</td>
              <td colspan="4">
                <input
                  type="text"
                  :value="
                    `${systemCompany.address}${
                      systemCompany.address != ''
                        ? ` ${systemCompany.detail_address}`
                        : ''
                    }`
                  "
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>업태</td>
              <td>
                <input
                  type="text"
                  :value="systemCompany.business_type"
                  readonly
                />
              </td>
              <td>종목</td>
              <td>
                <input
                  type="text"
                  :value="systemCompany.business_item"
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>담당자</td>
              <td>
                <input
                  type="text"
                  :value="users.find(x => x.account == userId).name"
                  readonly
                />
              </td>
              <td>연락처</td>
              <td>
                <input
                  type="text"
                  :value="systemCompany.phone_number"
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td colspan="4">
                <input type="text" :value="systemCompany.email" readonly />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mes_tbl_wrap bill_tbl bill_tbl2">
        <table class="mes_tbl">
          <tbody>
            <tr>
              <td rowspan="6">공급받는자</td>
              <td>등록번호</td>
              <td colspan="4">
                <input
                  type="text"
                  :value="getCompanyInfo.saup_no"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
                <button
                  type="button"
                  class="btn_sub2"
                  @click="companySearch = true"
                >
                  매출처 검색
                </button>
              </td>
            </tr>
            <tr>
              <td>상호</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.name"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
              <td>성명</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.representative"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
            </tr>
            <tr>
              <td>사업장주소</td>
              <td colspan="4">
                <input
                  type="text"
                  :value="
                    `${
                      getCompanyInfo.address != undefined
                        ? getCompanyInfo.address
                        : ''
                    }${
                      getCompanyInfo.detail_address != undefined &&
                      getCompanyInfo.detail_address != ''
                        ? ` ${getCompanyInfo.detail_address}`
                        : ''
                    }`
                  "
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
            </tr>
            <tr>
              <td>업태</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.business_type"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
              <td>종목</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.business_item"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
            </tr>
            <tr>
              <td>담당자</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.manager"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
              <td>연락처</td>
              <td>
                <input
                  type="text"
                  :value="getCompanyInfo.saup_no"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td colspan="4">
                <input
                  type="text"
                  :value="getCompanyInfo.email"
                  :disabled="newData.company_id == null"
                  :readOnly="newData.company_id != null"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <tbody>
            <tr>
              <td>비고1</td>
              <td colspan="2" class="text_left">
                <input
                  type="text"
                  :value="newData.comment1"
                  @input="
                    event => (newData.comment1 = event.target.value.trim())
                  "
                />
                <button
                  class="btn_sub2"
                  type="button"
                  @click="
                    newData.comment_length == 1
                      ? newData.comment_length++
                      : deleteCommentRow(1)
                  "
                >
                  {{ newData.comment_length == 1 ? '+' : '-' }}
                </button>
              </td>
            </tr>
            <tr v-show="newData.comment_length > 1">
              <td>비고2</td>
              <td colspan="2" class="text_left">
                <input
                  type="text"
                  :value="newData.comment2"
                  @input="
                    event => (newData.comment2 = event.target.value.trim())
                  "
                />
                <button
                  class="btn_sub2"
                  type="button"
                  @click="
                    newData.comment_length == 2
                      ? newData.comment_length++
                      : deleteCommentRow(2)
                  "
                >
                  {{ newData.comment_length == 2 ? '+' : '-' }}
                </button>
              </td>
            </tr>
            <tr v-show="newData.comment_length > 2">
              <td>비고3</td>
              <td colspan="2" class="text_left">
                <input
                  type="text"
                  :value="newData.comment3"
                  @input="event => (newData.comment3 = event.target.value)"
                />
                <button
                  class="btn_sub2"
                  type="button"
                  @click="deleteCommentRow(3)"
                >
                  -
                </button>
              </td>
            </tr>
            <tr>
              <td>작성방법</td>
              <td>
                <div class="input_radio">
                  <div>
                    <label
                      for="radio2_1"
                      class="label"
                      :class="{ active: newData.write_type == 0 }"
                      @click="newData.write_type = 0"
                    ></label>
                    <input
                      type="radio"
                      id="radio2_1"
                      name="search_opt"
                      :checked="newData.write_type == 0"
                    />
                    <label for="radio2_1" class="label_txt">직접입력</label>
                  </div>
                  <div>
                    <label
                      for="radio2_2"
                      class="label"
                      :class="{ active: newData.write_type == 1 }"
                      @click="newData.write_type = 1"
                    ></label>
                    <input
                      type="radio"
                      id="radio2_2"
                      name="search_opt"
                      :checked="newData.write_type == 1"
                    />
                    <label for="radio2_2" class="label_txt">수량단가</label>
                  </div>
                  <div>
                    <label
                      for="radio2_3"
                      class="label"
                      :class="{ active: newData.write_type == 2 }"
                      @click="newData.write_type = 2"
                    ></label>
                    <input
                      type="radio"
                      id="radio2_3"
                      name="search_opt"
                      :checked="newData.write_type == 2"
                    />
                    <label for="radio2_3" class="label_txt">공급가액</label>
                  </div>
                  <div>
                    <label
                      for="radio2_4"
                      class="label"
                      :class="{ active: newData.write_type == 3 }"
                      @click="newData.write_type = 3"
                    ></label>
                    <input
                      type="radio"
                      id="radio2_4"
                      name="search_opt"
                      :checked="newData.write_type == 3"
                    />
                    <label for="radio2_4" class="label_txt">합계금액</label>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  inputmode="decimal"
                  :disabled="newData.write_type != 3"
                  :value="$makeComma(inputTotalValue)"
                  @input="$inputNumberInt($event, $data, 'inputTotalValue')"
                />
                <button
                  class="btn_sub2"
                  type="button"
                  :disabled="newData.write_type != 3"
                  @click="
                    $makeNumber(inputTotalValue) > 0
                      ? appendDetailList($event)
                      : ''
                  "
                >
                  입력
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="tbl_wrap tbl_wrap2">
      <div>
        <div class="tbl_option">
          <h6>등록일자</h6>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="newData.write_date"
              @change="setWriteDate($event)"
            />
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox2"
              class="chk_box"
              :class="{ active: newData.additional_tax_yn }"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox2"
              @click="checkAdditionalTax()"
            />

            <label for="checkbox2" class="label_txt">가산세여부</label>
          </div>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>공급가액</th>
                <th>세액</th>
                <th>합계금액</th>
              </tr>
            </thead>
            <tbody>
              <tr class="total_list">
                <td class="text_right">
                  {{ `₩ ${$makeComma(newData.supply_value)}` }}
                </td>
                <td class="text_right">
                  {{
                    newData.tax_type_id != 1
                      ? `₩ 0`
                      : `₩ ${$makeComma(newData.tax_value)}`
                  }}
                </td>
                <td class="text_right">
                  {{ `₩ ${$makeComma(newData.total_value)}` }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div class="tbl_option">
          <h6>세부항목</h6>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl detail_list">
            <colgroup class="individual_col">
              <col v-for="(n, index) in 10" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>월</th>
                <th>일</th>
                <th>제품명</th>
                <th>규격</th>
                <th>수량</th>
                <th>단가</th>
                <th>공급가액</th>
                <th>세액</th>
                <th>비고</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in newData.tax_account_products_list"
                :key="index"
              >
                <td>
                  <input type="text" :value="getWriteDate.month" disabled />
                </td>
                <td>
                  <input
                    type="number"
                    min="1"
                    :value="item.write_day"
                    @input="setWriteDay($event, item)"
                  />
                </td>
                <td class="search_td">
                  <input
                    type="text"
                    :value="item.product_name"
                    @input="productInfoInput($event, item, 'product_name')"
                  />
                  <button
                    type="button"
                    class="btn_search2"
                    @click="productSearch = { state: true, searchIndex: index }"
                  >
                    검색
                  </button>
                </td>
                <td class="text_left">
                  <input
                    type="text"
                    :value="item.product_standard"
                    @input="productInfoInput($event, item, 'product_standard')"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(item.quantity)"
                    @input="
                      $inputNumberInt($event, item, 'quantity');
                      calDetailValue(item);
                    "
                    :disabled="newData.write_type == 2"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(item.cost)"
                    @input="
                      $inputNumberInt($event, item, 'cost');
                      calDetailValue(item);
                    "
                    :disabled="newData.write_type == 2"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(item.supply_value)"
                    @input="
                      $inputNumberInt($event, item, 'supply_value');
                      calDetailValue(item, 'supply');
                    "
                    :disabled="newData.write_type == 1"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="
                      newData.tax_type_id != 1 ? 0 : $makeComma(item.tax_value)
                    "
                    @input="
                      $inputNumberInt($event, item, 'tax_value');
                      calValue();
                    "
                    :disabled="
                      newData.write_type == 1 ||
                        newData.write_type == 2 ||
                        newData.tax_type_id != 1
                    "
                  />
                </td>
                <td class="text_left">
                  <input
                    type="text"
                    :value="item.comment"
                    @input="item.comment = $event.target.value.trim()"
                  />
                </td>
                <td>
                  <button
                    class="tbl_delete_btn"
                    type="button"
                    @click="deletDetailList(index)"
                  ></button>
                </td>
              </tr>
              <tr class="new newBtn">
                <td colspan="10" @click="appendDetailList()">
                  + 세부항목 추가
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div class="tbl_option">
          <h6>수금액</h6>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>현금</th>
                <th>수표</th>
                <th>어음</th>
                <th>외상미수금</th>
                <th>카드</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(newData.collection_cash)"
                    @input="$inputNumberInt($event, newData, 'collection_cash')"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(newData.collection_check)"
                    @input="
                      $inputNumberInt($event, newData, 'collection_check')
                    "
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(newData.collection_note)"
                    @input="$inputNumberInt($event, newData, 'collection_note')"
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(newData.collection_credit)"
                    @input="
                      $inputNumberInt($event, newData, 'collection_credit')
                    "
                  />
                </td>
                <td>
                  <input
                    type="decimal"
                    :value="$makeComma(newData.collection_card)"
                    @input="
                      $inputNumberInt($event, newData, 'collection_card');
                      calValue();
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="btn_wrap">
        <div class="input_radio">
          <label>이 금액을</label>
          <div>
            <label
              for="radio3_1"
              class="label"
              :class="{ active: newData.purpose_type == 1 }"
              @click="newData.purpose_type = 1"
            ></label>
            <input
              type="radio"
              id="radio3_1"
              name="search_opt"
              :checked="newData.purpose_type == 1"
            />
            <label for="radio3_1" class="label_txt">영수</label>
          </div>
          <div>
            <label
              for="radio3_2"
              class="label"
              :class="{ active: newData.purpose_type == 2 }"
              @click="newData.purpose_type = 2"
            ></label>
            <input
              type="radio"
              id="radio3_2"
              name="search_opt"
              :checked="newData.purpose_type == 2"
            />
            <label for="radio3_2" class="label_txt">청구 함</label>
          </div>
        </div>
        <button type="button" class="btn_sub2" @click="submitChkModal = true">
          계산서 등록
        </button>
      </div>
    </div>
    <company-search
      v-if="companySearch"
      :search_type="1"
      @onclose="companySearch = false"
      @onselect="selectCompany($event)"
    ></company-search>
    <resource-search
      v-if="productSearch.state"
      :filter_type="2"
      :pre_search_value="''"
      @onclose="productSearch = { state: false, searchIndex: -1 }"
      @onselect="selectProductId($event, productSearch.searchIndex)"
    ></resource-search>
    <two-button-modal
      :isModalOpen="submitChkModal"
      :modal_title="'계산서 등록'"
      :modal_content="'계산서를 등록하시겠습니까?'"
      @onclose="submitChkModal = false"
      @OnYesClick="submitForm()"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { formatDateNoHours } from '@/utils/filters';
import { yyyymmdd } from '@/utils/func';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';

export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin],
  components: { TwoButtonModal, CompanySearch, ResourceSearch },
  data() {
    return {
      inputTotalValue: 0,
      companySearch: false,
      productSearch: { state: false, searchIndex: -1 },
      submitChkModal: false,
    };
  },
  computed: {
    ...mapGetters({
      systemCompany: 'getSystemCompany',
      userId: 'getUserId',
      users: 'getUsersFromUserPage',
      companys: 'getCompany',
      products: 'getVisibleProduct',
      collectionType: 'getCollectionType',
      newData: 'getNewDataFromBillManagementPage',
      taxAccountProductsList: 'getTaxAccountProductsListFromNewData',
    }),
    getRate() {
      return this.newData.tax_type_id == 1 ? 1.1 : 1;
    },
    getCompanyInfo() {
      if (this.newData.company_id != null) {
        return this.findInfoFromId(this.companys, this.newData.company_id);
      } else return {};
    },
    getWriteDate() {
      let writeMonth = this.lodash
        .clonedeep(this.newData.write_date)
        .substring(5, 7);

      if (writeMonth.indexOf('0') == 0) {
        writeMonth = writeMonth.substr(1);
      }

      return {
        year: this.lodash.clonedeep(this.newData.write_date).substring(0, 4),
        month: writeMonth,
      };
    },
    getSumProductsCost() {
      const rv = {
        supplyValue: this.taxAccountProductsList
          .map(x => this.$makeNumber(x.supply_value))
          .reduce((a, b) => a + b, 0),
        taxValue:
          this.newData.tax_type_id == 1
            ? this.taxAccountProductsList
                .map(x => this.$makeNumber(x.tax_value))
                .reduce((a, b) => a + b, 0)
            : 0,
      };
      rv.totalValue = rv.supplyValue + rv.taxValue;

      return rv;
    },
    chkValid() {
      if (this.newData.company_id == null) {
        return '매출처';
      } else if (this.taxAccountProductsList.length < 1) {
        return '세부항목';
      } else if (this.$makeNumber(this.newData.total_value) < 1) {
        return '합계금액';
      } else if (
        this.tax_type_id == 1 &&
        this.$makeNumber(this.newData.tax_value) < 1
      ) {
        return '세액';
      } else return '';
    },
  },
  methods: {
    setWriteDate(e) {
      const writeDate = yyyymmdd(new Date(e.target.value));
      const todayDate = yyyymmdd(new Date());
      if (
        writeDate.substring(5, 7) < todayDate.substring(5, 7) &&
        10 < todayDate.substring(8) &&
        todayDate.substring(8) <= 31
      ) {
        this.openOneButtonModal(
          '알림',
          '세금계산서는 10일까지 등록하셔야합니다.<br>10일 이후에 등록하실경우 가산세가 부과됩니다.<br>가산세여부 체크표시를 해제하시면<br>현재 달 1일로 돌아가게 됩니다.',
        );
        this.newData.write_date = e.target.value;
        this.newData.additional_tax_yn = true;
      } else {
        this.newData.write_date = e.target.value;
        this.newData.additional_tax_yn = false;
      }
    },
    checkAdditionalTax() {
      let date = new Date();
      let changeDay = yyyymmdd(
        new Date(date.getFullYear(), date.getMonth(), 1),
      );
      if (this.newData.additional_tax_yn == true) {
        this.newData.write_date = changeDay;
        this.newData.additional_tax_yn = false;
      }
    },
    selectTaxType(id) {
      this.newData.tax_type_id = id;
      if (this.taxAccountProductsList.length > 0) this.calValue();
    },
    selectCompany(arg) {
      this.newData.company_id = arg.id;
      this.companySearch = false;
    },
    deleteCommentRow(index) {
      switch (index) {
        case 1:
          this.newData.comment1 = this.newData.comment2;
          this.newData.comment2 = this.newData.comment3;
          this.newData.comment3 = '';
          break;
        case 2:
          this.newData.comment2 = this.newData.comment3;
          this.newData.comment3 = '';
          break;
        case 3:
          this.newData.comment3 = '';
          break;
      }
      this.newData.comment_length--;
    },
    appendDetailList(e) {
      let appendInfo = {
        output_date: null,
        product_id: null,
        sales_product_id: null,
        product_name: '',
        product_standard: '',
        quantity: 0,
        cost: 0,
        supply_value: 0,
        tax_value: 0,
        comment: '',
        write_day: 1,
      };

      if (e != undefined) {
        // 합계금액으로 입력해서 들어올시
        appendInfo.supply_value = Math.ceil(
          this.$decimalDiv(
            this.$makeNumber(this.inputTotalValue),
            this.getRate,
          ),
        );
        if (this.newData.tax_type_id == 1) {
          appendInfo.tax_value =
            this.$makeNumber(this.inputTotalValue) - appendInfo.supply_value;
        } else {
          appendInfo.tax_value = Math.floor(
            this.$decimalMul(appendInfo.supply_value, 0.1),
          );
        }
        this.inputTotalValue = 0;
      }

      this.taxAccountProductsList.push(appendInfo);
      this.calValue();
    },
    setWriteDay(e, arg) {
      const maxDay = new Date(
        this.getWriteDate.year,
        this.getWriteDate.month,
        0,
      ).getDate();

      if (e.target.value == 0) e.target.value = 1;
      this.$inputNumberInt(e, arg, 'write_day', maxDay);
    },
    selectProductId(e, index) {
      this.productSearch = { state: false, searchIndex: -1 };

      let target = this.taxAccountProductsList[index];
      target.product_id = e.id;
      const targetProduct = this.findInfoFromId(
        this.products,
        target.product_id,
      );
      target.product_name = targetProduct.name;
      target.product_standard = targetProduct.standard;
    },
    productInfoInput(e, target, key) {
      if (target.product_id != null) target.product_id = null;
      target[key] = e.target.value.trim();
    },
    calDetailValue(arg, target) {
      if (target == undefined) {
        const totalValue = this.$decimalMul(
          this.$makeNumber(arg.quantity),
          this.$makeNumber(arg.cost),
        );

        arg.supply_value = Math.ceil(
          this.$decimalDiv(totalValue, this.getRate),
        );
        arg.tax_value = totalValue - this.$makeNumber(arg.supply_value);
      } else {
        arg.tax_value = Math.floor(
          this.$decimalMul(this.$makeNumber(arg.supply_value), 0.1),
        );
      }
      this.calValue();
    },
    calValue() {
      const cardSupplyValue = Math.ceil(
        this.$decimalDiv(
          this.$makeNumber(this.newData.collection_card),
          this.getRate,
        ),
      );

      this.newData.total_value =
        this.getSumProductsCost.totalValue -
        this.$makeNumber(this.newData.collection_card);
      this.newData.supply_value =
        Math.ceil(
          this.$decimalDiv(this.getSumProductsCost.totalValue, this.getRate),
        ) - cardSupplyValue;
      this.newData.tax_value =
        this.$makeNumber(this.newData.total_value) -
        this.$makeNumber(this.newData.supply_value);

      if (this.newData.total_value < 1) {
        this.newData.total_value = 0;
        this.newData.supply_value = 0;
        this.newData.tax_value = 0;
      }
    },
    reSyncProductsCost() {
      const supplyByTotalValue = Math.ceil(
        this.$decimalDiv(this.getSumProductsCost.totalValue, this.getRate),
      );

      if (supplyByTotalValue != this.getSumProductsCost.supplyValue) {
        const lastIndex = this.taxAccountProductsList[
          this.taxAccountProductsList.length - 1
        ];
        const charge = supplyByTotalValue - this.getSumProductsCost.supplyValue;

        lastIndex.supply_value =
          this.$makeNumber(lastIndex.supply_value) + charge;
        lastIndex.tax_value = this.$makeNumber(lastIndex.tax_value) - charge;
      }
    },
    deletDetailList(index) {
      this.taxAccountProductsList.splice(index, 1);

      if (this.taxAccountProductsList.length == 0) {
        this.newData.supply_value = 0;
        this.newData.tax_value = 0;
        this.newData.total_value = 0;
      } else {
        this.calValue();
      }
    },
    async submitForm() {
      this.submitChkModal = false;

      if (this.chkValid == '') {
        this.showSpinner();
        this.reSyncProductsCost();

        let payload = this.lodash.clonedeep(this.newData);

        payload.user_id = this.users.find(x => x.account == this.userId).id;
        payload.supply_value = this.$makeNumber(payload.supply_value);
        payload.tax_value =
          payload.tax_type_id == 1 ? this.$makeNumber(payload.tax_value) : 0;
        payload.total_value = this.$makeNumber(payload.total_value);
        payload.tax_account_products_list = payload.tax_account_products_list.filter(
          x => {
            x.supply_value = this.$makeNumber(x.supply_value);

            if (x.supply_value > 0) {
              if (Number(x.write_day) < 10) x.write_day = '0' + x.write_day;
              if (x.product_name.trim() == '')
                x.product_name = this.systemCompany.representative_item;
              x.output_date =
                this.newData.write_date.substring(0, 8) + x.write_day;
              x.quantity = this.$makeNumber(x.quantity);
              x.cost = this.$makeNumber(x.cost);

              if (payload.tax_type_id != 1) {
                x.tax_value = 0;
              } else {
                x.tax_value = this.$makeNumber(x.tax_value);
              }

              return x;
            }
          },
        );

        Object.keys(payload).forEach(el => {
          if (
            el.startsWith('collection_') &&
            this.$makeNumber(payload[el]) > 0
          ) {
            payload.tax_account_collection.push({
              collection_id: null,
              collection_type_id: this.collectionType.find(
                x => x.detail == el.split('_')[1],
              ).id,
              collection_value: this.$makeNumber(payload[el]),
            });
          }
        });

        console.log('payload', payload);

        await this.$store
          .dispatch('INSERT_BILL', payload)
          .then(() => {
            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.$store.commit('setNewDataToBillManagementPage', {
              write_date: formatDateNoHours(new Date()),
              company_id: null,
              user_id: null,
              tax_type_id: 1,
              tax_calc_type: 2,
              purpose_type: 1,
              supply_value: 0,
              tax_value: 0,
              total_value: 0,
              comment1: '',
              comment2: '',
              comment3: '',
              auto_write_yn: 2,
              commit_yn: 0,
              tax_account_products_list: [],
              tax_account_collection: [],
              comment_length: 1,
              write_type: 0,
              collection_cash: 0,
              collection_check: 0,
              collection_note: 0,
              collection_credit: 0,
              collection_card: 0,
            });
          })
          .catch(error => {
            console.log('error', error);
            this.openOneButtonModal(
              '등록 실패',
              '계산서 등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        this.openOneButtonModal(
          '등록불가',
          this.chkValid + '을(를) 확인해주세요.',
        );
        return;
      }
    },
  },
  async created() {
    await this.FETCH('FETCH_COMPANY', '거래처');
    await this.FETCH('FETCH_PRODUCT', '제품');

    if (this.systemCompany.id == undefined) {
      await this.FETCH('FETCH_SYSTEM_COMPANY', '사업자 정보');
    }
    if (this.users.length < 1) {
      await this.FETCH('FETCH_USER_LIST', '사용자 정보');
    }
    if (this.collectionType.length < 1) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
  },
};
</script>

<style></style>
