<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <div>
            <span class="option_txt">조회 기간</span>
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="start_date"
                  @change="setStartDate($event)"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="end_date"
                  @change="setEndDate($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <h6 class="views">
          조회수 : {{ transferList.length }}건
          <span
            >항목을 선택하시어 세부 항목에서 세금계산서를 등록해야 계산서 신고를
            진행할 수 있습니다.</span
          >
        </h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 10" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>거래처명</th>
                <th>공급가</th>
                <th>부가세</th>
                <th>합계액</th>
                <th>카드수금</th>
                <th>계산공급가</th>
                <th>계산부가세</th>
                <th>최종합계액</th>
                <th>진행단계</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in transferList"
                :key="item.id"
                @click="selectRow(index)"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>
                  {{
                    item.tax_type_id == 1
                      ? '과세'
                      : item.tax_type_id == 2
                      ? '영세'
                      : '면세'
                  }}
                </td>
                <td>
                  {{ findInfoFromId(companys, item.company_id).name }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.enroll_supply_value
                        : item.supply_value, | makeComma
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.enroll_tax_value
                        : item.tax_value, | makeComma
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.enroll_total_value
                        : item.total_value, | makeComma
                  }}
                </td>
                <td>
                  {{ $makeComma(item.card) }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.supply_value
                        : item.taxation_supply_value, | makeComma
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.tax_value
                        : item.taxation_tax_value, | makeComma
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    
                      item.auto_write_yn == 2
                        ? item.total_value
                        : item.taxation_total_value, | makeComma
                  }}
                </td>
                <td>
                  {{
                    item.auto_write_yn == 1 && item.id == null
                      ? '미등록'
                      : item.auto_write_yn == 2 || item.id != null
                      ? '등록'
                      : '신고'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>조회화면으로 돌아가기</h5>
          </button>
        </div>
        <div class="tbl_wrap">
          <div class="tbl_option">
            <h6>[수집기간 : {{ `${start_date} ~ ${end_date}` }}]</h6>
            <div class="input_radio">
              <label>과세형태 :</label>
              <div>
                <label
                  for="radio1_1"
                  class="label"
                  :class="{
                    active: transfer_data.tax_type_id == 1,
                  }"
                  @click="
                    transfer_data.auto_write_yn == 1 ? selectTaxType(1) : ''
                  "
                ></label>
                <input
                  type="radio"
                  id="radio1_1"
                  name="search_opt"
                  :checked="transfer_data.tax_type_id == 1"
                />
                <label for="radio1_1" class="label_txt">과세</label>
              </div>
              <div>
                <label
                  for="radio1_2"
                  class="label"
                  :class="{ active: transfer_data.tax_type_id == 3 }"
                  @click="
                    transfer_data.auto_write_yn == 1 ? selectTaxType(3) : ''
                  "
                ></label>
                <input type="radio" id="radio1_2" name="search_opt" />
                <label
                  for="radio1_2"
                  class="label_txt"
                  :checked="transfer_data.tax_type_id == 3"
                  >면세</label
                >
              </div>
              <div>
                <label
                  for="radio1_3"
                  class="label"
                  :class="{ active: transfer_data.tax_type_id == 2 }"
                  @click="
                    transfer_data.auto_write_yn == 1 ? selectTaxType(2) : ''
                  "
                ></label>
                <input
                  type="radio"
                  id="radio1_3"
                  name="search_opt"
                  :checked="transfer_data.tax_type_id == 2"
                />
                <label for="radio1_3" class="label_txt">영세율</label>
              </div>
            </div>
          </div>
          <div class="mes_tbl_wrap bill_tbl">
            <table class="mes_tbl">
              <tbody>
                <tr>
                  <td rowspan="6">공급자</td>
                  <td>등록번호</td>
                  <td colspan="4">
                    <input
                      type="text"
                      :value="systemCompany.saup_no"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td>상호</td>
                  <td>
                    <input type="text" :value="systemCompany.name" readonly />
                  </td>
                  <td>성명</td>
                  <td>
                    <input
                      type="text"
                      :value="systemCompany.representative"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td>사업장주소</td>
                  <td colspan="4">
                    <input
                      type="text"
                      :value="
                        `${systemCompany.address}${
                          systemCompany.address != ''
                            ? ` ${systemCompany.detail_address}`
                            : ''
                        }`
                      "
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td>업태</td>
                  <td>
                    <input
                      type="text"
                      :value="systemCompany.business_type"
                      readonly
                    />
                  </td>
                  <td>종목</td>
                  <td>
                    <input
                      type="text"
                      :value="systemCompany.business_item"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td>담당자</td>
                  <td>
                    <input
                      type="text"
                      :value="users.find(x => x.account == userId).name"
                      readonly
                    />
                  </td>
                  <td>연락처</td>
                  <td>
                    <input
                      type="text"
                      :value="systemCompany.phone_number"
                      readonly
                    />
                  </td>
                </tr>
                <tr>
                  <td>이메일</td>
                  <td colspan="4">
                    <input type="text" :value="systemCompany.email" readonly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mes_tbl_wrap bill_tbl bill_tbl2">
            <table class="mes_tbl">
              <tbody>
                <tr>
                  <td rowspan="6">공급받는자</td>
                  <td>등록번호</td>
                  <td colspan="4">
                    <input
                      type="text"
                      :value="getCompanyInfo.saup_no"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
                <tr>
                  <td>상호</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.name"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                  <td>성명</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.representative"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
                <tr>
                  <td>사업장주소</td>
                  <td colspan="4">
                    <input
                      type="text"
                      :value="
                        `${
                          getCompanyInfo.address != undefined
                            ? getCompanyInfo.address
                            : ''
                        }${
                          getCompanyInfo.detail_address != undefined &&
                          getCompanyInfo.detail_address != ''
                            ? ` ${getCompanyInfo.detail_address}`
                            : ''
                        }`
                      "
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
                <tr>
                  <td>업태</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.business_type"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                  <td>종목</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.business_item"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
                <tr>
                  <td>담당자</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.manager"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                  <td>연락처</td>
                  <td>
                    <input
                      type="text"
                      :value="getCompanyInfo.saup_no"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
                <tr>
                  <td>이메일</td>
                  <td colspan="4">
                    <input
                      type="text"
                      :value="getCompanyInfo.email"
                      :disabled="transfer_data.company_id == null"
                      :readOnly="transfer_data.company_id != null"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mes_tbl_wrap bill_tbl3">
            <table class="mes_tbl">
              <tbody>
                <tr>
                  <td>비고1</td>
                  <td colspan="2" class="text_left">
                    <input
                      type="text"
                      :value="transfer_data.comment1"
                      @input="
                        event =>
                          (transfer_data.comment1 = event.target.value.trim())
                      "
                    />
                    <button
                      class="btn_sub2"
                      type="button"
                      @click="
                        transfer_data.comment_length == 1
                          ? transfer_data.comment_length++
                          : deleteCommentRow(1)
                      "
                    >
                      {{ transfer_data.comment_length == 1 ? '+' : '-' }}
                    </button>
                  </td>
                </tr>
                <tr v-show="transfer_data.comment_length > 1">
                  <td>비고2</td>
                  <td colspan="2" class="text_left">
                    <input
                      type="text"
                      :value="transfer_data.comment2"
                      @input="
                        event =>
                          (transfer_data.comment2 = event.target.value.trim())
                      "
                    />
                    <button
                      class="btn_sub2"
                      type="button"
                      @click="
                        transfer_data.comment_length == 2
                          ? transfer_data.comment_length++
                          : deleteCommentRow(2)
                      "
                    >
                      {{ transfer_data.comment_length == 2 ? '+' : '-' }}
                    </button>
                  </td>
                </tr>
                <tr v-show="transfer_data.comment_length > 2">
                  <td>비고3</td>
                  <td colspan="2" class="text_left">
                    <input
                      type="text"
                      :value="transfer_data.comment3"
                      @input="
                        event => (transfer_data.comment3 = event.target.value)
                      "
                    />
                    <button
                      class="btn_sub2"
                      type="button"
                      @click="deleteCommentRow(3)"
                    >
                      -
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>작성방법</td>
                  <td>
                    <div class="input_radio">
                      <div>
                        <label
                          for="radio2_1"
                          class="label"
                          :class="{ active: transfer_data.write_type == 0 }"
                          @click="transfer_data.write_type = 0"
                        ></label>
                        <input
                          type="radio"
                          id="radio2_1"
                          name="search_opt"
                          :checked="transfer_data.write_type == 0"
                        />
                        <label for="radio2_1" class="label_txt">직접입력</label>
                      </div>
                      <div>
                        <label
                          for="radio2_2"
                          class="label"
                          :class="{ active: transfer_data.write_type == 1 }"
                          @click="transfer_data.write_type = 1"
                        ></label>
                        <input
                          type="radio"
                          id="radio2_2"
                          name="search_opt"
                          :checked="transfer_data.write_type == 1"
                        />
                        <label for="radio2_2" class="label_txt">수량단가</label>
                      </div>
                      <div>
                        <label
                          for="radio2_3"
                          class="label"
                          :class="{ active: transfer_data.write_type == 2 }"
                          @click="transfer_data.write_type = 2"
                        ></label>
                        <input
                          type="radio"
                          id="radio2_3"
                          name="search_opt"
                          :checked="transfer_data.write_type == 2"
                        />
                        <label for="radio2_3" class="label_txt">공급가액</label>
                      </div>
                      <div>
                        <label
                          for="radio2_4"
                          class="label"
                          :class="{ active: transfer_data.write_type == 3 }"
                          @click="transfer_data.write_type = 3"
                        ></label>
                        <input
                          type="radio"
                          id="radio2_4"
                          name="search_opt"
                          :checked="transfer_data.write_type == 3"
                        />
                        <label for="radio2_4" class="label_txt">합계금액</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      inputmode="decimal"
                      :disabled="transfer_data.write_type != 3"
                      :value="$makeComma(inputTotalValue)"
                      @input="$inputNumberInt($event, $data, 'inputTotalValue')"
                    />
                    <button
                      class="btn_sub2"
                      type="button"
                      :disabled="transfer_data.write_type != 3"
                      @click="
                        $makeNumber(inputTotalValue) > 0
                          ? appendDetailList($event)
                          : ''
                      "
                    >
                      입력
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tbl_wrap tbl_wrap2">
          <div>
            <div class="tbl_option">
              <h6>등록일자</h6>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="transfer_data.write_date"
                  @change="setWriteDate($event)"
                />
              </div>
              <div class="input_checkbox">
                <label
                  for="checkbox2"
                  class="chk_box"
                  :class="{ active: transfer_data.additional_tax_yn }"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input
                  type="checkbox"
                  id="checkbox2"
                  @click="checkAdditionalTax()"
                />

                <label for="checkbox2" class="label_txt">가산세여부</label>
              </div>
            </div>
            <div class="mes_tbl_wrap batch_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th>합계금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="total_list">
                    <td class="text_right">
                      {{ `₩ ${$makeComma(transfer_data.supply_value)}` }}
                    </td>
                    <td class="text_right">
                      {{
                        transfer_data.tax_type_id != 1
                          ? `₩ 0`
                          : `₩ ${$makeComma(transfer_data.tax_value)}`
                      }}
                    </td>
                    <td class="text_right">
                      {{ `₩ ${$makeComma(transfer_data.total_value)}` }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div class="tbl_option">
              <h6>세부항목</h6>
            </div>
            <div class="mes_tbl_wrap batch_tbl_wrap">
              <table class="mes_tbl detail_list">
                <colgroup class="individual_col">
                  <col v-for="(n, index) in 10" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>월</th>
                    <th>일</th>
                    <th>제품명</th>
                    <th>규격</th>
                    <th>수량</th>
                    <th>단가</th>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th>비고</th>
                    <th>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,
                    index) in transfer_data.tax_account_products_list"
                    :key="index"
                  >
                    <td>
                      <input type="text" :value="getWriteDate.month" disabled />
                    </td>
                    <td>
                      <input
                        type="number"
                        min="1"
                        :value="item.write_day"
                        @input="setWriteDay($event, item)"
                      />
                    </td>
                    <td class="search_td">
                      <input
                        type="text"
                        :value="
                          item.product_id != null
                            ? findInfoFromId(products, item.product_id).name
                            : item.product_name
                        "
                        @input="productInfoInput($event, item, 'product_name')"
                      />
                      <button
                        type="button"
                        class="btn_search2"
                        @click="
                          productSearch = { state: true, searchIndex: index }
                        "
                      >
                        검색
                      </button>
                    </td>
                    <td class="text_left">
                      <input
                        type="text"
                        :value="
                          item.product_id != null
                            ? findInfoFromId(products, item.product_id).standard
                            : item.product_standard
                        "
                        @input="
                          productInfoInput($event, item, 'product_standard')
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="decimal"
                        :value="$makeComma(item.quantity)"
                        @input="
                          $inputNumberInt($event, item, 'quantity');
                          calDetailValue(item);
                        "
                        :disabled="transfer_data.write_type == 2"
                      />
                    </td>
                    <td>
                      <input
                        type="decimal"
                        :value="$makeComma(item.cost)"
                        @input="
                          $inputNumberInt($event, item, 'cost');
                          calDetailValue(item);
                        "
                        :disabled="transfer_data.write_type == 2"
                      />
                    </td>
                    <td>
                      <input
                        type="decimal"
                        :value="$makeComma(item.supply_value)"
                        @input="
                          $inputNumberInt($event, item, 'supply_value');
                          calDetailValue(item, 'supply');
                        "
                        :disabled="transfer_data.write_type == 1"
                      />
                    </td>
                    <td>
                      <input
                        type="decimal"
                        :value="
                          transfer_data.tax_type_id != 1
                            ? 0
                            : $makeComma(item.tax_value)
                        "
                        @input="
                          $inputNumberInt($event, item, 'tax_value');
                          calValue();
                        "
                        :disabled="
                          transfer_data.write_type == 1 ||
                            transfer_data.write_type == 2 ||
                            transfer_data.tax_type_id != 1
                        "
                      />
                    </td>
                    <td class="text_left">
                      <input
                        type="text"
                        :value="item.comment"
                        @input="item.comment = $event.target.value.trim()"
                      />
                    </td>
                    <td>
                      <button
                        class="tbl_delete_btn"
                        type="button"
                        @click="deletDetailList(index)"
                      ></button>
                    </td>
                  </tr>
                  <tr class="new newBtn">
                    <td colspan="10" @click="appendDetailList()">
                      + 세부항목 추가
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div class="tbl_option">
              <h6>수금액</h6>
            </div>
            <div class="mes_tbl_wrap batch_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>현금</th>
                    <th>수표</th>
                    <th>어음</th>
                    <th>외상미수금</th>
                    <th>카드</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(transfer_data.collection_cash)"
                        @input="
                          $inputNumberInt(
                            $event,
                            transfer_data,
                            'collection_cash',
                          )
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(transfer_data.collection_check)"
                        @input="
                          $inputNumberInt(
                            $event,
                            transfer_data,
                            'collection_check',
                          )
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(transfer_data.collection_note)"
                        @input="
                          $inputNumberInt(
                            $event,
                            transfer_data,
                            'collection_note',
                          )
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(transfer_data.collection_credit)"
                        @input="
                          $inputNumberInt(
                            $event,
                            transfer_data,
                            'collection_credit',
                          )
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(transfer_data.collection_card)"
                        @input="
                          $inputNumberInt(
                            $event,
                            transfer_data,
                            'collection_card',
                          );
                          calValue();
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="btn_wrap">
            <div class="input_radio">
              <label>이 금액을</label>
              <div>
                <label
                  for="radio3_1"
                  class="label"
                  :class="{ active: transfer_data.purpose_type == 1 }"
                  @click="transfer_data.purpose_type = 1"
                ></label>
                <input
                  type="radio"
                  id="radio3_1"
                  name="search_opt"
                  :checked="transfer_data.purpose_type == 1"
                />
                <label for="radio3_1" class="label_txt">영수</label>
              </div>
              <div>
                <label
                  for="radio3_2"
                  class="label"
                  :class="{ active: transfer_data.purpose_type == 2 }"
                  @click="transfer_data.purpose_type = 2"
                ></label>
                <input
                  type="radio"
                  id="radio3_2"
                  name="search_opt"
                  :checked="transfer_data.purpose_type == 2"
                />
                <label for="radio3_2" class="label_txt">청구 함</label>
              </div>
            </div>
            <button
              type="button"
              class="btn_sub2"
              @click="submitChkModal = true"
              v-if="isPermissionOn"
            >
              {{
                transfer_data.auto_write_yn == 2 ? '계산서 수정' : '계산서 신고'
              }}
            </button>
          </div>
        </div>
        <resource-search
          v-if="productSearch.state"
          :filter_type="2"
          :pre_search_value="''"
          @onclose="productSearch = { state: false, searchIndex: -1 }"
          @onselect="selectProductId($event, productSearch.searchIndex)"
        ></resource-search>
        <two-button-modal
          :isModalOpen="submitChkModal"
          :modal_title="'계산서 신고'"
          :modal_content="'계산서를 신고하시겠습니까?'"
          @onclose="submitChkModal = false"
          @OnYesClick="submitForm()"
        ></two-button-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import FavoriteMixin from '@/mixins/favorite';
import SpinnerMixin from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import { formatDateNoHours } from '@/utils/filters';
import { yyyymmdd } from '@/utils/func';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
export default {
  mixins: [FetchMixin, FavoriteMixin, SpinnerMixin],
  components: { TwoButtonModal, ResourceSearch },
  data() {
    return {
      inputTotalValue: 0,
      productSearch: { state: false, searchIndex: -1 },
      submitChkModal: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      systemCompany: 'getSystemCompany',
      userId: 'getUserId',
      users: 'getUsersFromUserPage',
      products: 'getVisibleProduct',
      collectionType: 'getCollectionType',
      units: 'getUnitCodes',
      start_date: 'getTransferStartDateFromBillManagementPage',
      end_date: 'getTransferEndDateFromBillManagementPage',
      transfer_list: 'getTransferListFromBillManagementPage',
      selectedIndex: 'getTransferSelectedIndexFromBillManagementPage',
      transfer_data: 'getTransferDataFromBillManagementPage',
    }),
    getRate() {
      return this.transfer_data.tax_type_id == 1 ? 1.1 : 1;
    },
    getCompanyInfo() {
      if (this.transfer_data.company_id != null) {
        return this.findInfoFromId(
          this.companys,
          this.transfer_data.company_id,
        );
      } else return {};
    },
    getWriteDate() {
      let writeMonth = this.lodash
        .clonedeep(this.transfer_data.write_date)
        .substring(5, 7);

      if (writeMonth.indexOf('0') == 0) {
        writeMonth = writeMonth.substr(1);
      }
      return {
        year: this.lodash
          .clonedeep(this.transfer_data.write_date)
          .substring(0, 4),
        month: writeMonth,
      };
    },
    chkValid() {
      if (this.transfer_data.company_id == null) {
        return '매출처';
      } else if (this.transfer_data.tax_account_products_list.length < 1) {
        return '세부항목';
      } else if (this.$makeNumber(this.transfer_data.total_value) < 1) {
        return '합계금액';
      } else if (
        this.tax_type_id == 1 &&
        this.$makeNumber(this.transfer_data.tax_value) < 1
      ) {
        return '세액';
      } else return '';
    },
    getSumProductsCost() {
      const rv = {
        supplyValue: this.transfer_data.tax_account_products_list
          .map(x => this.$makeNumber(x.supply_value))
          .reduce((a, b) => a + b, 0),
        taxValue:
          this.transfer_data.tax_type_id == 1
            ? this.transfer_data.tax_account_products_list
                .map(x => this.$makeNumber(x.tax_value))
                .reduce((a, b) => a + b, 0)
            : 0,
      };
      rv.totalValue = rv.supplyValue + rv.taxValue;

      return rv;
    },

    transferList() {
      if (this.transfer_list.length > 0) {
        let cloneFilter = this.lodash
          .clonedeep(this.transfer_list)
          .sort((a, b) => new Date(b.write_date) - new Date(a.write_date));

        //현금
        let cash = cloneFilter.map(x =>
          x.tax_account_collection
            .filter(x => x.collection_type_id === 1)
            .map(x => x.collection_value)
            .reduce((a, b) => a + b, 0),
        );
        //수표
        let check = cloneFilter.map(x =>
          x.tax_account_collection
            .filter(x => x.collection_type_id === 7)
            .map(x => x.collection_value)
            .reduce((a, b) => a + b, 0),
        );
        //어음
        let note = cloneFilter.map(x =>
          x.tax_account_collection
            .filter(x => x.collection_type_id === 4)
            .map(x => x.collection_value)
            .reduce((a, b) => a + b, 0),
        );
        //외상미수금
        let credit = cloneFilter.map(x =>
          x.tax_account_collection
            .filter(x => x.collection_type_id === 5)
            .map(x => x.collection_value)
            .reduce((a, b) => a + b, 0),
        );

        //카드계산.
        let card = cloneFilter.map(x =>
          x.tax_account_collection
            .filter(x => x.collection_type_id === 3)
            .map(x => x.collection_value)
            .reduce((a, b) => a + b, 0),
        );

        let addTaxTransferSrc = cloneFilter.map((x, index) => ({
          ...x,
          card: card[index],
          enroll_supply_value:
            x.tax_type_id == 1
              ? Math.ceil((this.$makeNumber(x.total_value) + card[index]) / 1.1)
              : Math.ceil(this.$makeNumber(x.total_value) + card[index]),
          enroll_tax_value:
            x.tax_type_id == 1
              ? Math.ceil(this.$makeNumber(x.total_value)) +
                card[index] -
                Math.ceil((this.$makeNumber(x.total_value) + card[index]) / 1.1)
              : 0,
          enroll_total_value:
            Math.ceil(this.$makeNumber(x.total_value)) + card[index],
          taxation_supply_value:
            x.tax_type_id == 1
              ? Math.ceil((this.$makeNumber(x.total_value) - card[index]) / 1.1)
              : this.$makeNumber(x.total_value) - card[index],
          taxation_tax_value:
            x.tax_type_id == 1
              ? this.$makeNumber(x.total_value) -
                card[index] -
                Math.ceil((this.$makeNumber(x.total_value) - card[index]) / 1.1)
              : 0,
          taxation_total_value: x.total_value - card[index],
          collection_cash: cash[index],
          collection_check: check[index],
          collection_note: note[index],
          collection_credit: credit[index],
          collection_card: card[index],
        }));
        return addTaxTransferSrc;
      } else {
        return [];
      }
    },
  },
  methods: {
    setWriteDate(e) {
      const writeDate = yyyymmdd(new Date(e.target.value)); //등록날짜.
      const todayDate = yyyymmdd(new Date()); //오늘 날짜.
      if (
        writeDate.substring(5, 7) < todayDate.substring(5, 7) &&
        10 < todayDate.substring(8) &&
        todayDate.substring(8) <= 31
      ) {
        this.openOneButtonModal(
          '알림',
          '세금계산서는 10일까지 등록하셔야합니다.<br>10일 이후에 등록하실경우 가산세가 부과됩니다.<br>가산세여부 체크표시를 해제하시면<br>현재 달 1일로 돌아가게 됩니다. ',
        );
        this.transfer_data.write_date = e.target.value;
        this.transfer_data.additional_tax_yn = true;
      } else {
        this.transfer_data.write_date = e.target.value;
        this.transfer_data.additional_tax_yn = false;
      }
    },
    checkAdditionalTax() {
      let date = new Date();
      let changeDay = yyyymmdd(
        new Date(date.getFullYear(), date.getMonth(), 1),
      );
      if (this.transfer_data.additional_tax_yn == true) {
        this.transfer_data.write_date = changeDay;
        this.transfer_data.additional_tax_yn = false;
      }
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit(
            'setTransferStartDateToBillManagementPage',
            e.target.value,
          );
          this.FETCH_BILL_TRANSFER();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setTransferStartDateToBillManagementPage',
          yyyymmdd(new Date()),
        );
        this.FETCH_BILL_TRANSFER();
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.start_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit(
            'setTransferEndDateToBillManagementPage',
            e.target.value,
          );
        }
        this.FETCH_BILL_TRANSFER();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setTransferEndDateToBillManagementPage',
          yyyymmdd(new Date()),
        );
        this.FETCH_BILL_TRANSFER();
      }
    },
    async FETCH_BILL_TRANSFER() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_BILL_TRANSFER', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '계산서 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async selectRow(index) {
      this.$store.commit('setTransferSelectedIndexToBillManagementPage', index);
      if (index != -1) {
        let transList = this.lodash.clonedeep(this.transferList[index]);
        Object.keys(transList).forEach(el => {
          if (el.startsWith('comment') != '') {
            transList.comment_length = 1;
          }
        });
        transList.tax_account_products_list = transList.tax_account_products_list.map(
          x => {
            x.write_day = x.output_date.substring(8);
            if (x.write_day.indexOf('0') == 0) {
              x.write_day = x.write_day.substr(1);
            }
            return x;
          },
        );
        transList.write_type = 0;
        if (transList.write_date == null || transList.write_date == undefined) {
          transList.write_date = formatDateNoHours(new Date());
        }
        this.$store.commit('setTransferDataToBillManagementPage', transList);
      } else {
        this.$store.commit('setTransferDataToBillManagementPage', []);
      }
    },
    selectTaxType(id) {
      this.transfer_data.tax_type_id = id;
      if (this.transfer_data.tax_account_products_list.length > 0)
        this.calValue();
    },
    deleteCommentRow(index) {
      switch (index) {
        case 1:
          this.transfer_data.comment1 = this.transfer_data.comment2;
          this.transfer_data.comment2 = this.transfer_data.comment3;
          this.transfer_data.comment3 = '';
          break;
        case 2:
          this.transfer_data.comment2 = this.transfer_data.comment3;
          this.transfer_data.comment3 = '';
          break;
        case 3:
          this.transfer_data.comment3 = '';
          break;
      }
      this.transfer_data.comment_length--;
    },
    appendDetailList(e) {
      let appendInfo = {
        output_date: null,
        product_id: null,
        sales_product_id: null,
        product_name: '',
        product_standard: '',
        quantity: 0,
        cost: 0,
        supply_value: 0,
        tax_value: 0,
        comment: '',
        write_day: 1,
      };
      if (e != undefined) {
        appendInfo.supply_value = Math.ceil(
          this.$decimalDiv(
            this.$makeNumber(this.inputTotalValue),
            this.getRate,
          ),
        );
        if (this.transfer_data.tax_type_id == 1) {
          appendInfo.tax_value =
            this.$makeNumber(this.inputTotalValue) - appendInfo.supply_value;
        } else {
          appendInfo.tax_value = Math.floor(
            this.$decimalMul(appendInfo.supply_value, 0.1),
          );
        }
        this.inputTotalValue = 0;
      }
      this.transfer_data.tax_account_products_list.push(appendInfo);
      this.calValue();
    },
    setWriteDay(e, arg) {
      const maxDay = new Date(
        this.getWriteDate.year,
        this.getWriteDate.month,
        0,
      ).getDate();
      if (e.target.value == 0) e.target.value = 1;
      this.$inputNumberInt(e, arg, 'write_day', maxDay);
    },

    selectProductId(e, index) {
      this.productSearch = { state: false, searchIndex: -1 };

      let taxProduct = this.transfer_data.tax_account_products_list[index];

      taxProduct.product_id = e.id;
      const targetProduct = this.findInfoFromId(
        this.products,
        taxProduct.product_id,
      );
      taxProduct.product_name = targetProduct.name;
      taxProduct.product_standard = targetProduct.standard;
    },
    productInfoInput(e, target, key) {
      if (target.product_id != null) target.product_id = null;
      target[key] = e.target.value.trim();
    },
    calDetailValue(arg, target) {
      if (target == undefined) {
        const totalValue = this.$decimalMul(
          this.$makeNumber(arg.quantity),
          this.$makeNumber(arg.cost),
        );

        arg.supply_value = Math.ceil(
          this.$decimalDiv(totalValue, this.getRate),
        );
        arg.tax_value = totalValue - this.$makeNumber(arg.supply_value);
      } else {
        arg.tax_value = Math.floor(
          this.$decimalMul(this.$makeNumber(arg.supply_value), 0.1),
        );
      }
      this.calValue();
    },
    calValue() {
      const cardSupplyValue = Math.ceil(
        this.$decimalDiv(
          this.$makeNumber(this.transfer_data.collection_card),
          this.getRate,
        ),
      );

      this.transfer_data.total_value =
        this.getSumProductsCost.totalValue -
        this.$makeNumber(this.transfer_data.collection_card);
      this.transfer_data.supply_value =
        Math.ceil(
          this.$decimalDiv(this.getSumProductsCost.totalValue, this.getRate),
        ) - cardSupplyValue;
      this.transfer_data.tax_value =
        this.$makeNumber(this.transfer_data.total_value) -
        this.$makeNumber(this.transfer_data.supply_value);

      if (this.transfer_data.total_value < 1) {
        this.transfer_data.total_value = 0;
        this.transfer_data.supply_value = 0;
        this.transfer_data.tax_value = 0;
      }
    },
    reSyncProductsCost() {
      const supplyByTotalValue = Math.ceil(
        this.$decimalDiv(this.getSumProductsCost.totalValue, this.getRate),
      );

      if (supplyByTotalValue != this.getSumProductsCost.supplyValue) {
        const lastIndex = this.transfer_data.tax_account_products_list[
          this.transfer_data.tax_account_products_list.length - 1
        ];
        const charge = supplyByTotalValue - this.getSumProductsCost.supplyValue;

        lastIndex.supply_value =
          this.$makeNumber(lastIndex.supply_value) + charge;
        lastIndex.tax_value = this.$makeNumber(lastIndex.tax_value) - charge;
      }
    },
    deletDetailList(index) {
      this.transfer_data.tax_account_products_list.splice(index, 1);

      if (this.transfer_data.tax_account_products_list.length == 0) {
        this.transfer_data.supply_value = 0;
        this.transfer_data.tax_value = 0;
        this.transfer_data.total_value = 0;
      } else {
        this.calValue();
      }
    },
    async submitForm() {
      this.submitChkModal = false;
      if (this.chkValid == '') {
        this.showSpinner();
        this.reSyncProductsCost();

        let payload = this.lodash.clonedeep(this.transfer_data);
        payload.auto_write_yn = 2;
        payload.tax_calc_type = 2;
        payload.comment_length = 1;
        payload.user_id = this.users.find(x => x.account == this.userId).id;
        payload.supply_value = this.$makeNumber(payload.supply_value);
        payload.tax_value =
          payload.tax_type_id == 1 ? this.$makeNumber(payload.tax_value) : 0;
        payload.total_value = this.$makeNumber(payload.total_value);
        payload.tax_account_products_list = payload.tax_account_products_list.filter(
          x => {
            x.supply_value = this.$makeNumber(x.supply_value);

            if (x.supply_value > 0) {
              if (Number(x.write_day) < 10) x.write_day = '0' + x.write_day;
              if (x.product_name == '')
                x.product_name = this.systemCompany.representative_item;
              if (x.product_id != null) {
                let productSearch = this.findInfoFromId(
                  this.products,
                  x.product_id,
                );
                x.product_name = productSearch.name;
                x.product_standard = productSearch.standard;
              }

              x.output_date =
                this.transfer_data.write_date.substring(0, 8) + x.write_day;
              x.quantity = this.$makeNumber(x.quantity);
              x.cost = this.$makeNumber(x.cost);

              if (payload.tax_type_id != 1) {
                x.tax_value = 0;
              } else {
                x.tax_value = this.$makeNumber(x.tax_value);
              }
              return x;
            }
          },
        );
        payload.tax_account_collection = [];
        Object.keys(payload).forEach(el => {
          if (
            el.startsWith('collection_') &&
            this.$makeNumber(payload[el]) > 0
          ) {
            payload.tax_account_collection.push({
              collection_id: null,
              collection_type_id: this.collectionType.find(
                x => x.detail == el.split('_')[1],
              ).id,
              collection_value: this.$makeNumber(payload[el]),
            });
          }
        });
        try {
          if (this.transfer_data.auto_write_yn == 1) {
            await this.$store
              .dispatch('INSERT_BILL', payload)
              .then(() => {
                this.openOneButtonModal(
                  '등록 성공',
                  '성공적으로 등록하였습니다.',
                );
                this.$store.commit('setNewDataToBillManagementPage', {
                  write_date: formatDateNoHours(new Date()),
                  company_id: null,
                  user_id: null,
                  tax_type_id: 1,
                  tax_calc_type: 2,
                  purpose_type: 1,
                  supply_value: 0,
                  tax_value: 0,
                  total_value: 0,
                  comment1: '',
                  comment2: '',
                  comment3: '',
                  auto_write_yn: 2,
                  commit_yn: 0,
                  tax_account_products_list: [],
                  tax_account_collection: [],
                  comment_length: 1,
                  write_type: 0,
                  collection_cash: 0,
                  collection_check: 0,
                  collection_note: 0,
                  collection_credit: 0,
                  collection_card: 0,
                });
                this.selectRow(-1);
              })
              .catch(() => {
                this.openOneButtonModal(
                  '등록 실패',
                  '계산서 등록 중 오류가 발생했습니다.',
                );
              })
              .finally(() => {
                this.hideSpinner();
              });
          } else {
            await this.$store
              .dispatch('UPDATE_BILL', payload)
              .then(() => {
                this.openOneButtonModal(
                  '수정 성공',
                  '성공적으로 수정하였습니다.',
                );

                this.$store.commit('setTransferDataToBillManagementPage', {
                  id: null,
                  write_date: formatDateNoHours(new Date()),
                  company_id: null,
                  user_id: null,
                  tax_type_id: 1,
                  tax_calc_type: 2,
                  purpose_type: 1,
                  supply_value: 0,
                  tax_value: 0,
                  total_value: 0,
                  comment1: '',
                  comment2: '',
                  comment3: '',
                  auto_write_yn: 2, //자동완성 여부
                  commit_yn: 0,
                  tax_account_products_list: [],
                  tax_account_collection: [],
                  comment_length: 1,
                  write_type: 0,
                  collection_cash: 0,
                  collection_check: 0,
                  collection_note: 0,
                  collection_credit: 0,
                  collection_card: 0,
                });
                this.selectRow(-1);
              })
              .catch(() => {
                this.openOneButtonModal(
                  '등록 실패',
                  '계산서 신고 중 오류가 발생했습니다.',
                );
              })
              .finally(() => {
                this.hideSpinner();
              });
          }
          this.FETCH_BILL_TRANSFER();
        } catch {
          this.openOneButtonModal(
            '오류',
            '계산서 등록 또는 수정 중 오류가 발생하였습니다.',
          );
          this.hideSpinner();
        }
      }
      return;
    },
  },

  async created() {
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit(
        'setTransferEndDateToBillManagementPage',
        yyyymmdd(date),
      );
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit(
        'setTransferStartDateToBillManagementPage',
        yyyymmdd(date2),
      );
    }

    await this.FETCH_BILL_TRANSFER();

    await this.FETCH('FETCH_PRODUCT', '제품');
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.systemCompany.id == undefined) {
      await this.FETCH('FETCH_SYSTEM_COMPANY', '사업자 정보');
    }
    if (this.users.length < 1) {
      await this.FETCH('FETCH_USER_LIST', '사용자 정보');
    }
    if (this.collectionType.length < 1) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
